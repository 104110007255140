.our-process {
  background: url('https://kad-website-ui-images.s3.amazonaws.com/whiteBackground.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__spacer {
    height: 107px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    height: fit-content;
    padding: 0 75px 75px 75px;

    h2 {
      margin: 0px;
    }

    > p {
      width: 40vw;
    }
  }
  &__header {
    margin: 0px;
    margin-bottom: 64px;

    font-size: 40px;
  }

  &__all-content-item-wrapper {
    flex-direction: column;
    width: 60vw;
    display: flex;
    justify-content: space-between;
  }

  &__all-content-item {
    width: 25vw;
    height: fit-content;
  }

  &__content-item-wrapper {
    position: relative;

    display: flex;
    align-items: inherit;
  }

  &__content-item-wrapper-left {
    flex-direction: row-reverse;
  }

  &__content-item-process-circle {
    width: 100px;
    z-index: 0;
  }

  &__process-image-text-wrapper {
    position: relative;
  }

  &__process-text-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    margin-top: 0px;
    margin-left: 24px;
    margin-right: 0%;

    text-align: left;
  }

  &__process-text-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    margin-top: 0px;
    margin-right: 24px;
    margin-left: 0%;

    text-align: right;
  }

  &__content-item-process-text-wrapper {
    position: absolute;
    left: 50%;
    top: 45%;

    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
    width: 100px;
    margin-left: -50px;
    margin-top: -16px;

    > span {
      font-size: 14px;
    }
  }

  &__content-item-process-number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-top: 8px;

    border: solid 1px #707070;
    border-radius: 124px;
    background-color: #364655;
    color: white;
  }

  &__content-item-left {
    margin-top: 10vw;
  }
}

@media screen and (max-width: 1000px) {
  .our-process {
    background-attachment: unset;
    &__wrapper {
      padding: 0px 15px 48px 15px;

      h2 {
        font-size: 23px;
      }

      > p {
        width: 100%;
      }
    }

    &__spacer {
      height: 30px;
    }

    &__all-content-item-wrapper {
      font-size: 16px;
      width: 100%;
    }

    &__all-content-item {
      width: 100%;
    }

    &__process-image-text-wrapper {
      position: relative;
      width: 50%;
      height: fit-content;
    }

    &__process-text-right {
      margin-left: 0px;
      margin-right: 0%;
    }

    &__process-text-left {
      margin-right: 0px;
    }

    &__content-item-process-circle {
      // width: 70%;
      z-index: 0;
    }

    &__content-item-wrapper {
      position: relative;

      display: flex;
      align-items: inherit;
      margin-bottom: 4vw;
    }
  }
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {
  .our-process {
    &__content-item-process-number {
      width: 30px;
      height: 30px;
      margin-top: 8px;
    }
  }
}
@media screen and (min-width: 1000px) and (max-width: 1300px) {
  .our-process {
    &__content-item-process-number {
      width: 30px;
      height: 30px;
      margin-top: 8px;
    }
  }
}
@media screen and (min-width: 750px) and (max-width: 1000px) {
  .our-process {
    &__content-item-process-circle {
      // width: 60%;
    }
  }
}

@media screen and (min-width: 550px) and (max-width: 750px) {
  .our-process {
    &__content-item-process-circle {
      // width: 75%;
    }
  }
}

@media screen and (max-width: 390px) {
  .our-process {
    background: url('https://kad-website-ui-images.s3.amazonaws.com/smallWhiteBackground.png');

    &__content-item-process-text-wrapper > span {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 340px) {
  .our-process {
    &__content-item-process-text-wrapper {
      top: 50%;
    }
    &__content-item-process-circle {
      // width: 95%;
    }
    &__content-item-process-text-wrapper > span {
      font-size: 15px;
    }

    &__content-item-process-number {
      width: 28px;
      height: 28px;
      margin-top: 8px;
    }
  }
}
