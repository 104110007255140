.mobile-navbar {
  &__button-wrapper {
    position: fixed;
    bottom: 1vw;
    right: 1vw;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 20vw;
    height: 15vw;

    z-index: 3;
  }

  &__closed {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 0;
    height: 352px;
    transition: 0.5s;

    a {
      font-size: 0px;
    }
  }

  &__open {
    position: fixed;
    bottom: 0px;
    left: 0px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100vw;
    height: 352px;

    text-align: left;
    background-color: #364655;
    color: white;
    z-index: 2;

    a {
      margin-left: 64px;
      transition: 0.5s;
      text-decoration: none;
    }

    a:link {
      color: white;
    }

    a:visited {
      color: white;
    }

    a:hover {
      color: white;
    }

    a:active {
      color: white;
    }
  }

  &__hamburger &__line {
    width: 50px;
    height: 5px;
    background-color: black;

    display: block;
    margin: 8px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &__hamburger:hover {
    cursor: pointer;
  }
}

#hamburger.mobile-navbar__is-active .mobile-navbar__line:nth-child(2) {
  opacity: 0;
}

#hamburger.mobile-navbar__is-active .mobile-navbar__line:nth-child(1) {
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
  background-color: #ecf0f1;
}

#hamburger.mobile-navbar__is-active .mobile-navbar__line:nth-child(3) {
  -webkit-transform: translateY(-13px) rotate(-45deg);
  -ms-transform: translateY(-13px) rotate(-45deg);
  -o-transform: translateY(-13px) rotate(-45deg);
  transform: translateY(-13px) rotate(-45deg);
  background-color: #ecf0f1;
}
