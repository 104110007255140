.navbar {
  position: fixed;
  top: 0;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 91px;
  width: 100%;

  background-color: transparent;
  z-index: 1;
  transition: top 0.3s;

  a {
    display: block;
    padding: 15px;

    float: left;
    color: #f2f2f2;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    opacity: 0.8;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  a:hover {
    opacity: 1;
  }

  &__scroll {
    a {
      opacity: 0.8;
      color: black;
    }

    :hover {
      opacity: 1;
      color: black;
    }
    background: white;
  }

  &__logo {
    height: 50px;
  }

  &__link-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__indicator {
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 50px;
    background-color: #2a83e9;
    will-change: left, right;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &__link--active {
    background: rgba(42, 131, 233, 0.5);
    border-radius: 5px;
  }
}

@media screen and (max-width: 1000px) {
  .navbar {
    justify-content: start;
    height: 30px;

    align-items: left;

    &__logo {
      height: 24px;
      margin-left: 8px;
    }
  }
}
