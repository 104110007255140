.what-we-do {
  background: url('https://kad-website-ui-images.s3.amazonaws.com/blueBackground.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__spacer {
    height: 107px;
  }

  &__wrapper {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    padding: 0 75px 75px 75px;

    h1 {
      margin: 0px;
      margin-bottom: 48px;

      color: white;
      font-size: 40px;
    }

    h2 {
      margin: 0px;

      color: white;
    }
  }

  &__content-wrapper {
    display: flex;
    text-align: left;
    width: 28vw;
    margin-bottom: 50px;

    color: white;

    p {
      margin-left: 15px;

      font-size: 18px;
    }
    h2 {
      margin-left: 15px;

      font-size: 26px;
    }
  }

  &__all-content-wrapper {
    display: flex;
    width: 60vw;

    img {
      width: 20%;
    }
  }

  &__text-wrapper {
    display: flex;
    align-items: center;
  }

  &__text {
    text-align: left;
    margin: 0px;
    margin-left: 32px;

    color: white;
    font-size: 18px;
    line-height: 44px;
  }
}

@media screen and (max-width: 1000px) {
  .what-we-do {
    background-attachment: unset;
    h1 {
      margin-bottom: 8px;

      font-size: 23px;
      font-weight: 600;
    }

    &__spacer {
      height: 30px;
    }

    &__wrapper {
      padding: 0px 15px 48px 15px;
    }

    &__text {
      font-size: 14px;
      line-height: 30px;
    }

    &__all-items-content-wrapper {
      color: white;
      font-size: 12px;

      h2 {
        font-size: 18px;
        font-weight: bold;
      }
    }

    &__all-content-wrapper {
      flex-direction: column-reverse;
      width: 100%;
    }

    &__content-mobile-wrapper {
      margin-top: 36px;
    }

    &__content-wrapper-right {
      display: flex;
      flex-direction: row-reverse;

      text-align: right;
    }

    &__content-wrapper-left {
      display: flex;
      text-align: initial;
    }

    &__content-text-wrapper-left {
      margin-left: 16px;
    }
    &__content-text-wrapper-right {
      margin-right: 16px;
    }
  }
}

@media screen and (max-width: 390px) {
  .what-we-do {
    background: url('https://kad-website-ui-images.s3.amazonaws.com/smallBlueBackground.png');
  }
}
