.our-business {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding: 75px;
  padding-right: 0px;

  background: url('https://kad-website-ui-images.s3.amazonaws.com/whiteBackground.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__info-cards-wrapper {
    display: grid;
    width: 60vw;

    grid-template-columns: auto auto auto;
    column-gap: 60px;
    row-gap: 83px;
  }

  &__main-wrapper {
    display: flex;

    justify-content: space-between;
  }

  &__main {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      font-size: 40px;
    }
  }

  &__values-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 46px;

    text-align: center;

    h1 {
      font-size: 40px;
    }
  }

  &__all-image-icon-wrapper {
    display: flex;
    justify-content: space-between;
    width: 60vw;
    padding: 50px;
  }

  &__image-icon-wrapper {
    display: flex;
    flex-direction: column;
    width: 15%;

    text-align: center;
    font-size: 18px;
  }

  &__image-icon {
    width: 100%;
  }

  &__text {
    width: 60vw;

    text-align: center;
    line-height: 2;
    font-size: 18px;
  }
}

@media screen and (max-width: 1000px) {
  .our-business {
    padding: 0px;
    background-attachment: unset;

    &__main {
      h1 {
        font-size: 23px;
      }
    }

    &__text {
      width: 90vw;

      font-size: 14px;
    }

    &__values-wrapper {
      margin-bottom: 50px;
      margin-top: 31px;

      h1 {
        font-size: 23px;
      }
    }

    &__info-header {
      text-align: initial;

      font-size: 14px;
      font-weight: bold;
    }

    &__info-text-wrapper {
      width: 80%;
    }

    &__info-text {
      margin-bottom: 0px;
      text-align: initial;

      font-size: 12px;
    }

    &__all-image-icon-wrapper {
      flex-wrap: wrap;
      justify-content: space-between;
      width: 80%;
      padding: 0;
      padding-top: 50px;
    }

    &__image-icon-wrapper {
      width: 114px;
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 390px) {
  .our-business {
    background: url('https://kad-website-ui-images.s3.amazonaws.com/smallWhiteBackground.png');
  }
}
