.our-story {
  display: flex;
  justify-content: center;
  padding: 75px;

  background: url('https://kad-website-ui-images.s3.amazonaws.com/whiteBackground.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__wrapper {
    width: 85%;

    h1 {
      font-size: 40px;
      text-align: center;
    }
    p {
      font-size: 18px;
      line-height: 1.5;
      letter-spacing: 0.36px;
      text-align: left;
    }
  }

  &__our-founders-image-wrapper {
    display: flex;
    justify-content: space-evenly;

    img {
      width: 40%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .our-story {
    padding: 0px 15px 48px 15px;
    background-attachment: unset;

    &__wrapper {
      width: 100%;

      h2 {
        font-size: 23px;
      }

      > p {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .our-story {
    background: url('https://kad-website-ui-images.s3.amazonaws.com/smallWhiteBackground.png');
  }
}
