.tools-of-the-trade {
  background: url('https://kad-website-ui-images.s3.amazonaws.com/blueBackground.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &__spacer {
    height: 107px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    padding: 0 75px 75px 75px;

    text-align: center;
    color: white;
    h2 {
      margin: 0px;

      font-size: 40px;
    }

    h3 {
      font-size: 26px;
    }

    p {
      text-align: center;
      font-size: 18px;
    }
  }
  &__text-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60vw;
  }

  &__text-image-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__neg-marg {
    margin-top: -56px;
  }

  &__text-image {
    width: 20vw;
  }

  &__platforms-text-image {
    width: 15vw;
  }

  &__text-image-main-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;

    text-align: center;

    p {
      font-size: 18px;
    }
  }

  &__languages {
    width: 30vw;

    text-align: left !important;
    p {
      text-align: left !important;
    }
  }

  &__tools-frameworks {
    width: 30vw;

    text-align: right !important;
    p {
      text-align: right !important;
    }
  }

  &__platforms {
    width: 30vw;

    text-align: left !important;
    p {
      text-align: left !important;
    }
  }

  &__all-icon-wrapper {
    display: grid;
    padding: 0 134px;
    height: 100%;

    grid-template-columns: auto auto auto;
    column-gap: 17px;
  }
}

@media screen and (max-width: 1000px) {
  .tools-of-the-trade {
    background-attachment: unset;
    &__wrapper {
      padding: 0px 15px 48px 15px;

      h2 {
        font-size: 23px;
      }

      > p {
        width: 100%;
      }
    }

    &__spacer {
      height: 30px;
    }

    &__text-header-wrapper {
      width: 100%;
    }

    &__text-image {
      width: 45%;
      height: fit-content;
    }

    &__platforms-text-image {
      width: 35%;
    }

    &__text-image-wrapper {
      align-items: center;
    }

    &__languages {
      width: 50%;
      h3 {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 0px;
      }
    }

    &__tools-frameworks {
      width: 50%;

      h3 {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 0px;
      }
    }

    &__platforms {
      width: 50%;

      h3 {
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 0px;
      }
    }

    &__neg-marg {
      margin-top: 58px;
    }
  }
}

@media screen and (max-width: 390px) {
  .tools-of-the-trade {
    background: url('https://kad-website-ui-images.s3.amazonaws.com/smallBlueBackground.png');
  }
}
