.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 990px;
  align-items: center;

  background: url('https://kad-website-ui-images.s3.amazonaws.com/NewHeader.png');
  background-attachment: inherit;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &__text-wrapper {
    margin-top: 32px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__text-top {
    color: #364655;
    font-size: 16px;
  }

  &__text-bottom {
    width: 224px;
    margin-top: 6px;

    color: #364655;
    font-size: 16px;
    text-align: center;
  }

  &__heading {
    color: #ffffff;
    font-size: 48px;
  }
}

@media screen and (max-width: 1000px) {
  .hero {
    height: 286px;
    background-size: 868px;
    background-attachment: unset;

    &__text-top {
      font-size: 14px;
    }

    &__text-bottom {
      width: 200px;
      font-size: 12px;
    }

    &__heading {
      font-size: 24px;
    }
  }
}
