.contact-us {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 600px;
  padding: 75px 75px 0px 75px;

  background: url('https://kad-website-ui-images.s3.amazonaws.com/whiteBackground.png');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  text-align: center;

  footer {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-weight: 900;
      font-size: x-large;
    }
  }

  &__main-form-wrapper {
    display: flex;
    justify-content: center;
    height: fit-content;

    text-align: center;

    :hover {
      color: white !important;
    }
    p {
      width: 434px;
    }
  }

  &__main-form-contact-us {
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 0 50px;

    background-color: #364655;
    text-align: left;
    color: white !important;

    :hover {
      color: white !important;
    }
    a {
      color: white !important;
      text-decoration-line: none;
    }
  }

  &__form-wrapper {
    height: fit-content;
    width: 60vw;
    margin-left: 8px;
    padding: 24px;

    background-color: #364655;

    color: white !important;

    :hover {
      color: white !important;
    }
    h2 {
      margin-top: inherit;

      text-align: left;
    }
  }

  &__form {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: 100%;

    input {
      width: 100%;

      border: none;
      border-bottom: 1px solid white;
      background: inherit;
      color: white;

      &:focus {
        outline: none !important;
      }
    }
  }

  &__form-item-wrapper {
    display: flex;
  }

  &__button-submit {
    margin-top: 16px;
  }

  &__button-clear {
    margin-top: 16px;
    margin-left: 16px;
  }

  &__form-item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 100%;
    margin-top: 24px;
  }

  &__logo {
    width: 250px;
    margin-right: 16px;
  }

  &__form-error-message {
    color: red;
  }
  &__form-good-message {
    color: green;
  }
}

.nameInput {
  width: 92% !important;
}

@media screen and (max-width: 1000px) {
  .contact-us {
    height: fit-content;
    padding: 21px 15px 0px 15px;
    background-attachment: unset;

    &__form-wrapper {
      width: 100%;
      margin-left: 0px;

      h2 {
        font-size: 23px;
        text-align: center;
      }
    }

    &__header-info-wrapper {
      display: flex;
      flex-direction: column;
    }

    &__header-info-a-wrapper {
      display: flex;
      flex-direction: column;
      margin: auto;
      margin-top: 24px;

      text-decoration: none;

      a {
        margin-top: 8px;

        font-size: 12px;
        text-align: center;
      }

      a:link {
        color: white;
      }

      a:visited {
        color: white;
      }

      a:hover {
        color: white;
      }

      a:active {
        color: white;
      }
    }

    footer {
      margin-top: 36px;

      &__logo {
        width: 164px;
      }

      p {
        font-size: unset;
      }
    }
  }
}

@media screen and (max-width: 390px) {
  .contact-us {
    background: url('https://kad-website-ui-images.s3.amazonaws.com/smallWhiteBackground.png');
  }
}
