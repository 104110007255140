.button {
  position: relative;
  padding: 8px;
  border-radius: 27px;
  color: #f7f7f7;
  font-size: 17px;
  background-color: #2a83e9;
  border: 0px;
  -webkit-transition: all 0.2s ease-in-out; /* Safari */
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  background-position: center;
  transition: background 0.8s;

  &:hover {
    background-color: #f7f7f7;
    color: #2a83e9;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 14%), 0 1px 7px 0 rgb(0 0 0 / 12%),
      0 3px 1px -1px rgb(0 0 0 / 20%);
    background: #f7f7f7 radial-gradient(circle, transparent 1%, #f7f7f7 1%)
      center/15000%;
  }

  &:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }

  &__sec {
    background-color: #d1d0c9;
    color: black;
  }

  &__sec:hover {
    background-color: #364655;
    color: white !important;
    background: #364655 radial-gradient(circle, transparent 1%, #364655 1%)
      center/15000%;
  }

  &__sec:active {
    background-color: white;
    background-size: 100%;
    transition: background 0s;
  }
  &__third {
    background-color: red;
    color: white;
  }

  &__third:hover {
    background-color: #d1d0c9;
    color: black !important;
    background: #d1d0c9 radial-gradient(circle, transparent 1%, #d1d0c9 1%)
      center/15000%;
  }

  &__third:active {
    background-color: #364655;
    background-size: 100%;
    transition: background 0s;
  }

  &__small {
    width: 150px;
  }

  &__medium {
  }

  &__large {
    width: 260px;
  }

  // &:after {
  //   background-color: #6eb9f7;
  //   background-size: 100%;
  //   transition: background 0s;
  // }
}
